<template>
    
</template>

<script>
	export default {
		name: "CreateEditClient"
	}
</script>

<style scoped>

</style>